<template>
    <layout>
        <page-header :title="title" :items="items" />

        <awesome-table
            id="articles"
            :labels="labels"
            :items="articles"
            :pagination="pagination"
            :options="{
                sort: true,
                filters: true,
                link: true,
                columnsSelector: true
            }"
            :filters="filters"
            @row-click="$router.push(`/articles/${$event.id}/edit`)"
            @mouse-wheel-click="$openNewTab(`/articles/${$event.id}/edit`)"
            @pagination-change="onPaginationChange"
            @add-button-click="$router.push('/articles/add')"
            @filter-change="onFilterChange"
            @elements-moved="onElementsMoved"
        >
            <template #[`items.actions`]="{ item }">
                <a
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="removeArticle(item.id)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    page: {
        title: 'Articles'
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Articles',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Articles',
                    active: true
                }
            ],
            articles: [],
            labels: [
                { value: 'title', filter: 'removeSpecialChars', width: '45%' },
                { value: 'slug', width: '35%' },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '20%',
                    type: 'date'
                },
                {
                    value: 'actions',
                    sortable: false,
                    width: '5%',
                    filterable: false
                }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters)
        };
    },

    created() {
        this.fetchArticles();
    },

    methods: {
        ...mapActions({
            getArticles: 'articles/index',
            deleteArticle: 'articles/delete',
            changeOrder: 'articles/changeOrder'
        }),

        async fetchArticles() {
            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } = await this.getArticles(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.articles = rows;
                this.pagination.pages = Math.ceil(
                    count / this.pagination.perPage
                );
                this.pagination.total = count;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async removeArticle(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this article?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteArticle(id);

                this.articles = this.articles.filter(
                    article => article.id !== id
                );

                this.pagination.total--;

                this.$toaster('Article has been deleted!');
            } catch (error) {
                console.error(error);

                const message =
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                        ? error.response.data.error
                        : 'Something went wrong!';

                this.$toasterError(message);
            }
        },

        async onElementsMoved(elements) {
            try {
                await this.changeOrder(elements);

                this.$toaster('Order has been changed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchArticles();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };

            this.fetchArticles();
        }
    }
};
</script>
