var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('awesome-table',{attrs:{"id":"articles","labels":_vm.labels,"items":_vm.articles,"pagination":_vm.pagination,"options":{
            sort: true,
            filters: true,
            link: true,
            columnsSelector: true
        },"filters":_vm.filters},on:{"row-click":function($event){return _vm.$router.push(("/articles/" + ($event.id) + "/edit"))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/articles/" + ($event.id) + "/edit"))},"pagination-change":_vm.onPaginationChange,"add-button-click":function($event){return _vm.$router.push('/articles/add')},"filter-change":_vm.onFilterChange,"elements-moved":_vm.onElementsMoved},scopedSlots:_vm._u([{key:"items.actions",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticClass:"action-icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeArticle(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }